import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Api from "../_helpers/api";

export function useProvideAuth() {
  const [admin, setAdmin] = useState(null);
  const loginEndpoint = "/api/v1/login";
  const logOutEndpoint = "/api/v1/logout";

  const saveSessionAuthHeader = (sessionAuthHeader) => {
    localStorage.setItem("token", JSON.stringify(sessionAuthHeader));
    setSessionAuthHeader(sessionAuthHeader);
  };

  const getSessionAuthHeader = () => {
    const token = localStorage.getItem("token");
    return JSON.parse(token);
  };
  const deleteSessionAuthHeader = () => {
    localStorage.removeItem("token");
  };

  const [sessionAuthHeader, setSessionAuthHeader] = useState(
    getSessionAuthHeader()
  );

  const logIn = (credential, callback) => {
    return Api.post(loginEndpoint, { admin: credential })
      .then((res) => res.data)
      .then(({ admin, authentication_token }) => {
        setAdmin({
          ...admin,
          authentication_token: authentication_token,
        });
        saveSessionAuthHeader({
          token: authentication_token,
          email: admin.email,
          first_name: admin.first_name,
        });
      })
      .then(() => {
        callback();
      });
  };

  const logOut = (callback) => {
    return Api.delete(logOutEndpoint)
      .then((res) => res.data)
      .then(() => {
        deleteSessionAuthHeader();
        setAdmin(null);
      })
      .then(() => {
        window.location = "/";
      });
  };

  const navigate = useNavigate();

  const checkSession = () => {
    Api.get("/api/v1/me", {
      headers: {
        "X-ADMIN-EMAIL": sessionAuthHeader?.email,
        "X-ADMIN-TOKEN": sessionAuthHeader?.token,
      },
    }).then((res) => {
      if (res.status !== 200) {
        logOut().then((r) => console.log(r));
      }
    });
  };

  return {
    admin,
    logIn,
    logOut,
    sessionAuthHeader,
    checkSession,
  };
}
