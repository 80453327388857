import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./pages/admin/Login";
import { PrivateRoute } from "./components/PrivateRoute";
import Api from "./_helpers/api";
import { store } from "./redux";
import { Provider as ReduxProvider } from "react-redux";
import AdminCategoriesPage from "./pages/admin/categories/AdminCategoriesPage";
import AdminUniversesPage from "./pages/admin/universes/AdminUniversesPage";
import AdminInspirationsPage from "./pages/admin/inspirations/AdminInspirationsPage";
import AdminUsersPage from "./pages/admin/AdminUsersPage";
import AdminContactPage from "./pages/admin/contacts/AdminContactPage";
import AdminProductsPage from "./pages/admin/products/AdminProductsPage";
import AdminProductAddEditPage from "./pages/admin/products/AdminProductAddEditPage";
import { useAuth } from "./hooks/useAuth";
import AdminCategoriesAddEditPage from "./pages/admin/categories/AdminCategoriesAddEditPage";
import AdminUniversesAddEditPage from "./pages/admin/universes/AdminUniversesAddEditPage";
import AdminInspirationAddEditPage from "./pages/admin/inspirations/AdminInspirationAddEditPage";
import AdminMaterialAddEditPage from "./pages/admin/materials/AdminMaterialAddEditPage";
import AdminMaterialsPage from "./pages/admin/materials/AdminMaterialsPage";
import AdminRealisationsPage from "./pages/admin/realisations/AdminRealisationsPage";
import AdminRealisationAddEditPage from "./pages/admin/realisations/AdminRealisationAddEditPage";
import AdminContactShowPage from "./pages/admin/contacts/AdminContactShowPage";
// import CategoryPage from "./pages/CategoryPage";
import { Helmet } from "react-helmet";
import AdminReferencesAddEditPage from "./pages/admin/references/AdminReferencesAddEditPage";
import AdminReferencesPage from "./pages/admin/references/AdminReferencesPage";
import AdminProductsByCategory from "./pages/admin/categories/AdminProductsByCategory";
import AdminFiltersPage from "./pages/admin/filters/AdminFiltersPage";
import AdminFiltersAddEditPage from "./pages/admin/filters/AdminFiltersAddEditPage";
import AdminTagsAddEditPage from "./pages/admin/tags/AdminTagsAddEditPage";
import AdminTagsEditAddPage from "./pages/admin/tags/AdminAddEditTagsPage";
import LogRocket from "logrocket";
import AdminFamiliesPage from "./pages/admin/families/AdminFamiliesPage";
import AdminFamiliesAddEditPage from "./pages/admin/families/AdminAddEditFamiliesPage";
import { init, trackPages } from "insights-js";
// import GroupPage from "./pages/GroupPage";
import NotFoundPage from "./pages/404";

const App = () => {
  const { sessionAuthHeader } = useAuth();

  useEffect(() => {
    LogRocket.init("mister-e/adm");

    init("rXY8CNa8Nidxlteq");
    trackPages();

    Api.defaults.headers.common["X-CSRF-TOKEN"] =
      // eslint-disable-next-line no-undef
      document.querySelector("[name=csrf-token]").content;
    if (sessionAuthHeader) {
      Api.defaults.headers.common["X-ADMIN-EMAIL"] = sessionAuthHeader.email;
      Api.defaults.headers.common["X-ADMIN-TOKEN"] = sessionAuthHeader.token;
    }
  });

  return (
    <ReduxProvider store={store}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ADM</title>
        <link rel="canonical" href="https://adm-mobilier.com" />
        <meta
          name="description"
          content="Choisir vos sièges et tables, créer votre décor, est un acte déterminant ! Pour le réussir, plus qu’un fournisseur, nous sommes votre partenaire."
        />
        <script>
          {`var _paq = window._paq = window._paq || [];
            /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
            (function() {
              var u="https://mistere.matomo.cloud/";
              _paq.push(['setTrackerUrl', u+'matomo.php']);
              _paq.push(['setSiteId', '2']);
              var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
              g.async=true; g.src='//cdn.matomo.cloud/mistere.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
            })();`}
        </script>
      </Helmet>
      <Routes>
        {/* <Route path={"/"} exact element={<Homepage />} /> */}

        {/* <Route
          path={"/selection-terrasse-2024"}
          exact
          element={<SelectionTerrasses />}
        />
        <Route
          path={"/promotion-terrasse"}
          exact
          element={<PromoTerrasses />}
        />

        <Route path={"/mobiliers-accessoires"} element={<ProductsPage />} />
        <Route
          path={"/recherche/:query_url?"}
          element={<ResultsSearchPage />}
        />
        <Route
          path={"/category/:slug"}
          element={<ProductsPage isCategorypage />}
        />
        <Route
          path={"/group/:group_name"}
          element={<ProductsPage isGroupPage />}
        />
        <Route
          path={"/mobiliers-accessoires/:product_slug"}
          element={<ProductPage />}
        />
        <Route path={"/nos-realisations"} element={<UniversesPage />} />
        <Route
          path={"/nos-realisations/:slug"}
          element={<DetailRealisation />}
        />
        <Route path={"/nos-inspirations"} element={<InspirationsPage />} />
        <Route path={"/references"} element={<ReferencePage />} />
        <Route
          path={"/secteur-d-activites/:universe_id"}
          element={<UniversePage />}
        />
        <Route path={"/mentions-legales"} element={<MentionsLegales />} />
        <Route
          path={"/nos-inspirations/:inspiration_slug"}
          element={<InspirationPage />}
        />
        <Route
          path={"/realisations/:realisation_slug"}
          element={<RealisationPage />}
        />
        <Route path={"/presentation"} element={<Presentation />} />
        <Route path={"/services"} element={<Services />} />
        <Route path={"/services/showroom"} element={<Showroom />} />
        <Route
          path={"/services/entrepot-stockage-atelier"}
          element={<EntrepotStockageAtelier />}
        />
        <Route
          path={"/services/livraison-montage"}
          element={<LivraisonMontage />}
        />
        <Route
          path={"/services/eco-responsabilite"}
          element={<EcoResponsabilite />}
        />
        <Route path={"contact"} element={<Contact />} /> */}
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <AdminProductsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/products"
          element={
            <PrivateRoute>
              <AdminProductsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/products/new"
          element={
            <PrivateRoute>
              <AdminProductAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/products/:product_id"
          element={
            <PrivateRoute>
              <AdminProductAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/families"
          element={
            <PrivateRoute>
              <AdminFamiliesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/families/new"
          element={
            <PrivateRoute>
              <AdminFamiliesAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/families/:family_id"
          element={
            <PrivateRoute>
              <AdminFamiliesAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/categories"
          element={
            <PrivateRoute>
              <AdminCategoriesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/categories/new"
          element={
            <PrivateRoute>
              <AdminCategoriesAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/categories/:category_slug"
          element={
            <PrivateRoute>
              <AdminCategoriesAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/filters"
          element={
            <PrivateRoute>
              <AdminFiltersPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/filters/new"
          element={
            <PrivateRoute>
              <AdminFiltersAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/filters/:filter_id"
          element={
            <PrivateRoute>
              <AdminFiltersAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/categories/:category_id/products/order"
          element={
            <PrivateRoute>
              <AdminProductsByCategory />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/univers"
          element={
            <PrivateRoute>
              <AdminUniversesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/universes/new"
          element={
            <PrivateRoute>
              <AdminUniversesAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/universes/:universe_slug"
          element={
            <PrivateRoute>
              <AdminUniversesAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/inspirations"
          element={
            <PrivateRoute>
              <AdminInspirationsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/inspirations/new"
          element={
            <PrivateRoute>
              <AdminInspirationAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/inspirations/:inspiration_id"
          element={
            <PrivateRoute>
              <AdminInspirationAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/realisations"
          element={
            <PrivateRoute>
              <AdminRealisationsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/realisations/new"
          element={
            <PrivateRoute>
              <AdminRealisationAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/realisations/:realisation_id"
          element={
            <PrivateRoute>
              <AdminRealisationAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/references"
          element={
            <PrivateRoute>
              <AdminReferencesPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/admin/references/new"
          element={
            <PrivateRoute>
              <AdminReferencesAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/references/:reference_id"
          element={
            <PrivateRoute>
              <AdminReferencesAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/materials"
          element={
            <PrivateRoute>
              <AdminMaterialsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/materials/new"
          element={
            <PrivateRoute>
              <AdminMaterialAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/materials/:material_id"
          element={
            <PrivateRoute>
              <AdminMaterialAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/prospects"
          element={
            <PrivateRoute>
              <AdminUsersPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/contacts"
          element={
            <PrivateRoute>
              <AdminContactPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/contacts/:contact_id"
          element={
            <PrivateRoute>
              <AdminContactShowPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/tags"
          element={
            <PrivateRoute>
              <AdminTagsAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/tags/:tag_id"
          element={
            <PrivateRoute>
              <AdminTagsEditAddPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/tags/new"
          element={
            <PrivateRoute>
              <AdminTagsEditAddPage />
            </PrivateRoute>
          }
        />
        <Route path="*" Component={NotFoundPage}></Route>
      </Routes>
    </ReduxProvider>
  );
};

export default App;
